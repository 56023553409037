@font-face {
  font-family: "MEBlack";
  src: url('./MonumentExtended-Black.ttf');
}

@font-face {
  font-family: "MENormal";
  src: url('./MonumentExtended-Regular.ttf');
}

@font-face {
  font-family: "FuMiddle";
  src: url('./tt0143m.ttf');
}

@font-face {
  font-family: "FuBlod";
  src: url('./tt0144m.ttf');
}
